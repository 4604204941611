import React, { useState } from "react"
import styles from "./portfolio.module.scss"
import { graphql } from "gatsby"
import ImageGrid from "./ImageGrid"
import { StyledHeader } from "../../Header/Header"
import { IDM } from "../../Echipamente/IDM"
import { useIntl } from "gatsby-plugin-intl"
import { Helmet } from "react-helmet"
import Layout from "../../Layout/Layout"

const PortfolioClicked = ({ data, pageContext }) => {

  const getPortfolio = (portfolioDataArray) => {
    const imageArray = portfolioDataArray.portfolio.edges
      .map(node => { if (node.node.childImageSharp !== null) return node.node.childImageSharp.fluid })

    return {
      imageArray: imageArray,
      folderName: pageContext.folderName.toLowerCase().replace(/ /g, '-'),
      headerImage: portfolioDataArray.header.childImageSharp.fluid
    }
  }

  const [state] = useState(() => {
    return getPortfolio(data)
  })
  const intl = useIntl()

  const descriptionArray = intl.formatMessage({ id: 'projects.' + state.folderName + '.description' }) !== "" ? intl.formatMessage({ id: 'projects.' + state.folderName + '.description' }).split("_") : ""

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"G4H - " + intl.formatMessage({ id: 'projects.' + state.folderName + '.title' })}</title>
        <meta name="description" content={intl.formatMessage({ id: 'projects.' + state.folderName + '.description' })}></meta>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.relative}>
          <StyledHeader
            echipamente
            image={state.headerImage}>
            <IDM isProject={true} />
          </StyledHeader>
          <div className={styles.content}>
            <h1 className={styles.project_title}>{intl.formatMessage({ id: 'projects.' + state.folderName + '.title' })}</h1>
            {
              descriptionArray ? descriptionArray.map((description, i) => {
                return <h2 key={i} className={styles.project_description}>{description}</h2>
              }) : ""
            }
            <ImageGrid images={state.imageArray} alt={state.folderName.replace(/-/g, " ")} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PortfolioClicked;

export const query = graphql` query MdPhoto($folderName:String!) {
  
    portfolio: allFile(filter: {relativeDirectory: {eq: $folderName}, extension: {eq: "webp"}, sourceInstanceName: {eq: "projects"}}, sort: {order: ASC, fields: name} ) {
      edges {
        node {
          childImageSharp {
            fluid(fit: COVER, webpQuality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    header: file(relativePath: { eq: "fundal.webp" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
  }
`